/*** Mobile Screens ***/
#sidebar{
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  margin-bottom: 25px;
  float: right;
  position: relative;
  background-color: #f1f1f1;

  .Level-1{
    display: none;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: $themeColor;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 80%;
    a{
        font-weight: bold;
    text-transform: uppercase;
    font-size: 2em;
      padding: 5px 30px 5px 30px;
      display: block;
      text-decoration: none;

    }
  }
  ul{
  *{
    font-size: 1em;
    text-transform: uppercase;
  }

  .current{
    position: relative;
    &:after{
      display: block;
      height: 1px;
      background-color: $themeColor;
      position: absolute;
      width: 70%;
      content: '';
      left: 0px;
      bottom: 0px;
    }
  }
    ul{
      display: none;
      background-color: rgba(0,0,0, 0.05);
      li{
        a{
          /*** Level Indentation ***/
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      a{
        display: block;
        padding: 5px 30px 5px 30px;
        text-decoration: none;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);

        span{
          color: $textDark;
          @include transition($t1);
        }
        &:hover span{
          color: $themeColor !important;
        }
        &:hover{
          background-color: rgba(229, 232, 229,0.8);
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (min-width: 1000px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
