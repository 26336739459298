/* -------------------- General Site layouts -------------------- **/
*{
	font-family: $font-stack;
	color: #616161;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


/*** Page ***/
.Page_results,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: calc(1080px + 80px);
	width: 100%;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: $themeColor;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
//		border-bottom: 2px solid #cccccc;
	}

}

#dots {
    width: 100%;
    position: relative;
    height: 10px;
    z-index: 99999;
    bottom: 0;
    display: inline-block;
    text-align: center;
    .noticedot {
        width: 8px;
        height: 8px;
        background-color: #000;
        position: relative;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin: 0 2px;
        opacity: 0.6;
        cursor: pointer;
        &.selected {
            opacity: 1;
        }
    }
}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.8em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #4d4d4d !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
clear: both;
overflow: hidden;
cursor: pointer;
		@include transition($t1);
	display: block;
	width: 100%;
	max-width: 500px;
	width: 100%;
	max-width: 500px;
	padding: 10px 20px;
	text-transform: uppercase;
	border: 2px solid #ededed;
	color: #2b2b2b;
	padding-left: 50px;
	position: relative;
	margin: 5px 0px;
	&:before{
		@include transition($t1);
		content: '\f019';
		display: block;
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		opacity: 0;
		left: 10px;
			color: #901d2f;
		*{
			color: #901d2f;
		}
		@include transform(translateY(-100%));
	}
	&:after{
		@include transition($t1);
		display: block;
		content: '\f0f6';
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		left: 10px;
		opacity: 1;
			color: #901d2f;
		*{
			color: #901d2f;
		}
		@include transform(translateY(-50%));
	}
	&:hover{

			color: #901d2f;
		border: 2px solid #901d2f;
		&:before{
			color: #901d2f;
			opacity: 1;
			@include transform(translateY(-50%));
		}
		&:after{
			color: #901d2f;
			opacity: 0;
			@include transform(translateY(50%));
		}
	}

	.fileIcon{
		display: none;
	}
}

.upload a{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%; 
}


.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}



.searchToggle{
	right: 69px;
	@include transition($t1);
}

.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.searchToggle{
	position: absolute;
	z-index: 9999;
	top: 0px;
    cursor: pointer;
	right: 0px;
	height: 41px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #dbb95a;
	}
}

/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}


/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;

	border-top: 5px solid $themeColor;

	border-bottom: 5px solid $themeColor;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(0,0,0, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}

.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}


/*** Page ***/
.Page{
	.banner{
		height: 66vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}


/*** Menu ***/
#Menu{
	float: left;
    z-index: 60;
    width: 100%;
    text-align: center;
	display: inline-block;
	text-align: right;
	position: absolute;
	right: 200px;
	top: 13px;
	*{
		color: #fff;
		text-transform: uppercase;
		font-size: 0.9em;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
    	text-align: right;
		position: relative;
		.LevelOne{
			display: inline-block; 
			text-align: center;
			position: relative;
			a{    
				font-size: 1em;
				@include transition($t1);
				padding: 14px 20px 24px 20px;
			    font-size: 1em;
			    line-height: 3em;
			    display: inline-block; 
	   	 		line-height: 1.1em; 
			}
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		font-size: 1em;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: $themeColor;
	    border-radius: 0px;
	    margin-top: 0px;
	    *{
	    	color: #fff;
	    }
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
		    font-weight: bold;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(255,255,255,0.2) !important;
			}
		}
	}
	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}
/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/

#header{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9880;
}

.bannerText{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 8;
	width: 95%;
    font-size: 3.2em;
	@include transform($center);
	text-align: center;
    max-width: 1450px;
    margin: 0 auto;
	*{
		text-transform: uppercase;
		color: #fff;
	}
	.part-1{

	}
	.part-2{
		font-weight: bold;
	}
    .tagline {
        text-transform: inherit;
        font-size: 35px;
        line-height: 40px;
        padding: 0 30px;
        margin-top: 30px;
    }
}

#Home2{
	background-color: #f1f1f1;
	padding: 100px 20px;
	.inner{
		width: 800px;
	}
	li{
	margin-left: 30px !important;
    list-style: disc !important;
	}
	ul{
	margin-bottom: 15px;
	}
}

.pageTitle{
	text-transform: uppercase;
	font-size: 2.5em;
	line-height: 1.2em;
	color: #616161;
	font-weight: bold;
}

.ReadMore{
	color: #fff;
	width: 230px;
	display: inline-block;
	text-align: center;
	background-color: $themeColor;
	text-transform: uppercase;
	padding: 12px 25px;
	@include transition($t1);
	&:hover{
		background-color: #000 !important;
	}
}

.QuickLink{
	height: 300px;

	@include transition($t1);
	background-color: $themeColor;
	.QuickLinkInner{
		position: absolute;
		width: 100%;
		padding: 20px;
		top: 50%;
		left: 50%;
		@include transform($center);
	}
	*{
		color: #fff;
		text-transform: uppercase;
		text-align: center;
	}
	.QuickLinkBG{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 1;
		background-size: cover;
		background-position: center;
		@include transition($t1);
		background-image: url(../images/bg.jpg);
	}
	.overlayDark{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0.4;
		background-color: #000;
	}
	.QuickLinkTitle{
		font-size: 1.8em;
		margin-bottom: 10px;
	}
	.QuickLinkMore{
		font-size: 0.9em;
		opacity: 0;
	}
	.QuickLinkArrow{
		opacity: 0;
	}
	.moreIcon{
		display: inline-block;
		border: 1px solid #fff;
		@include border-radius(50%);
	    text-align: center;
	    padding: 5px;
	    font-size: 1.4em;
	    width: 40px;
	    height: 40px;
	    margin-top: 15px;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	&:hover{
		.QuickLinkBG{
			opacity: 0.6;
		}

		.QuickLinkArrow,
		.QuickLinkMore{
			opacity: 1 !important;
		}
	}
}

.HiddenMore{
	height: 0px;
	overflow: hidden;
}
.QuickLink-3,
.QuickLink-5,
.QuickLink-7,
.QuickLink-9{
	.QuickLinkBG{
		opacity: 0.4;
	}
	&:hover{
		background-color: #000;
		.QuickLinkBG{
			opacity: 0.6;
		}
	}
}
.QuickLink-2,
.QuickLink-4,
.QuickLink-9{
	height: 600px;
}

.newsInner{
	background-color: #fff;
}
#NewsSection{
    padding: 0px 20px 70px 20px;
    background-color: #f1f1f1;

    .newsHeading{
    	padding: 70px 0px;
    }

	#NewsPrev,
	#NewsNext{
	    font-size: 1.2em;
		background-size: contain;
		background-position: center;
		width: 50px;
		height: 50px;
		top: 50%;
		position: absolute;
		*{
		color: #fff;
		}
		@include transition($t1);
		@include border-radius(50%);
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		i{
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);
			display: none;
		}
	}
	#NewsPrev{
		left: 40px;
		background-image: url(../images/Previous2.png);
		@include transform( scale(1) translateX(-50%) translateY(-50%));
		&:hover{
			@include transform(translateX(-50%) translateY(-50%) scale(1.2));
		}

	}
	#NewsNext{
		right: 40px;
		background-image: url(../images/Next2.png);
		@include transform( scale(1) translateX(50%) translateY(-50%));
		&:hover{
			@include transform(translateX(50%) translateY(-50%) scale(1.2));
		}

	}
	a{
		position: absolute;
		bottom: 10px;
		right: 20px;
		text-transform: uppercase;
		color: $themeColor;
	}
	.NewsTitle{
		text-transform: uppercase;
		color: $themeColor;
		font-weight: bold;
		font-size: 1.4em;
	}
}

.newsImg{
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	position: absolute;
	top: 0px;
	left: 0px;
	width: 43%;
	height: 100%;
}
.newsText{
	padding-left: 45%;
}


#Home4{
    padding: 70px 20px 170px 20px;
    background-image: url(../images/BGnew.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-attachment: fixed;
    border: 30px solid #fff;
    .lighten {
        background-color: rgba(78,79,79,0.65);
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
    }
    .inner {
    	width: 1400px;
	}

    .CalendarHeading{
    	padding: 70px 0px;
    	color: #fff;
    }
    section{
    	min-height: 200px;
    }
	#CalendarPrev,
	#CalendarNext{
	    font-size: 1.2em;

		background-size: contain;
		background-position: center;
		width: 50px;
		height: 50px;
		top: 50%;
		@include transition($t1);
		position: absolute;
		*{
		color: #fff;
		}
		@include border-radius(50%);
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		i{
			display: none;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);
		}
		
	}
	*{
		color: #fff;
	}
	#CalendarPrev{
		left: 40px;
		background-image: url(../images/Previous.png);
		@include transform( scale(1) translateX(-50%) translateY(-50%));
		&:hover{
			@include transform(translateX(-50%) translateY(-50%) scale(1.2));
		}
	}
	#CalendarNext{
		right: 40px;
		background-image: url(../images/Next.png);
		@include transform( scale(1) translateX(50%) translateY(-50%));
		&:hover{
			@include transform(translateX(50%) translateY(-50%) scale(1.2));
		}
	}
	a{
		position: absolute;
		bottom: 10px;
		right: 20px;
		text-transform: uppercase;
		color: $themeColor;
	}

	.inner{
		position: relative;
		z-index: 8;
	}
	.darken{
		@include stretchCover(rgba(0,0,0, 0.5), absolute, 1)
	}
}

.DateSection{
	text-align: center;
	background-color: #fff;
}
.DateMonth{
	background-color: $themeColor;
	color: #fff;
	text-transform: uppercase;
	padding: 10px;
}
.DateDay{
	padding-bottom: 80%;
	span{
		font-weight: bold;
		font-size: 3em;
		position: absolute;
		display: inline-block;
		left: 50%;
		color: #616161 !important;
		top: 50%;
		@include transform($center);
	}
}
.DateContent{
	position: relative;
	a{
		position: absolute;
		display: block;
		bottom: 0px !important;
		right: auto !important;
		width: 100%;
		color: #fff !important;
		padding: 10px 0px;
		font-size: 0.9em;
		border-top: 1px solid #fff;
		text-transform: uppercase;
	}
}
.calTitle{
	font-size: 1.3em;
}
.calText{
	 min-height: 150px;
}

#Footer{
	background-color: #fff;

	.footerAddress{
		padding: 30px;
		padding-left: 5%;
	}
	.FooterBottom{
		padding: 45px 5%;
		background-color: #991005;
		*{
			color: #fff;
		}
		.ReadMore{
			color: $themeColor;
			background-color: #fff;
			&:hover{
				color: #fff !important;
			}
		}
	}
    .footerTop {
        background-color: #f1f1f1;
        margin-top: 30px;
    }
	.schoolTitle{
		font-weight: bold;
		text-transform: uppercase;
	}
}

.fse{
    font-size: 0.7em;
}

.socialLinks{
	position: fixed;
	z-index: 9;
	right: 15px;
	top: 50%;
	@include transform(translateY(-50%));
	a{
		display: block;
		clear: both;
		float: left;
		padding: 10px;
		i{
			color: #fff;
			opacity: 1;
			font-size: 1.2em;
			@include transition(all 0.2s);
			@include transform(rotate(0deg));
		}
		&:hover{
			i{
				@include transform(rotate(10deg));
			}
		} 
	}
}

.SocialScroll{
	.socialLinks a i{
		color: #a7a7a7 !important;
	}
}
.footerLink{
	display: inline-block;
	padding: 10px;
	img{
    	height: 70px;
	}
}

.ScrollDown{
	position: absolute;
	bottom: 40px;
	z-index: 9;
	left: 50%;
	img{
		margin-top: 20px;
		width: 40px;
	}
	*{
		color: #fff;
		text-transform: uppercase;
	}
	@include transform(translateX(-50%));
}
.topIcons{
	position: absolute;
	z-index: 98;
	top: 0px;
	right: 0px;
	padding: 20px;
	img{
		height: 25px;
	}
	a,span{
		padding-left: 15px;
	}
	*{
		display: inline-block;
		cursor: pointer;
	}
}
.homebtn{
	position: absolute;
	top: 20px;
	display: block;
	left: 20px;
	z-index: 70;
	img{
		height: 70px;
	}
	span{
		display: inline-block;
		height: 70px;
		background-color: #fff;
		margin-left: 15px;
	}
}

.PortalLink{
	&:after{
		display: none;
		position: absolute;
		content: '';
		border: 10px solid transparent;
		border-bottom-color: #d9d9d9;
		bottom: 7px;
   		margin-left: 2px;
  	}
	&:before{
		display: none;
		position: absolute;
		content: 'PARENT PORTAL';
		background-color: #d9d9d9;
		padding: 5px;
		color: #000;
		font-size: 0.7em;
		line-height: 1em;
		text-align: center;
		text-transform: uppercase;
		width: 70px;
    	bottom: -25px;
    	margin-left: -27px;
		@include border-radius(5px);
	}
	&:hover:after{
		display: block !important;}
	&:hover:before{
		display: block !important;}
}

.PortalLink2{
	&:after{
		display: none;
		position: absolute;
		content: '';
		border: 10px solid transparent;
		border-bottom-color: #d9d9d9;
		bottom: 7px;
   		margin-left: 2px;
  	}
	&:before{
		display: none;
		position: absolute;
		content: 'STAFF/STUDENT PORTAL';
		background-color: #d9d9d9;
		padding: 5px;
		color: #000;
		font-size: 0.7em;
		line-height: 1em;
		text-align: center;
		width: 100px;
		text-transform: uppercase;
		width: 130px;
    	bottom: -25px;
    	margin-left: -27px;
		@include border-radius(5px);
	}
	&:hover:after{
		display: block !important;}
	&:hover:before{
		display: block !important;}
}

.overlay1{
    position: absolute;
    opacity: 0.5;
    bottom: -5.5%;
    left: -10%;
    height: 86%;
    z-index: 3;
}

#Home2{
	overflow: hidden;
    #twitterPrev,
	#twitterNext{
	    font-size: 1.2em;
		background-size: contain;
		background-position: center;
		width: 50px;
		height: 50px;
		top: calc(50% - 40px);
		@include transition($t1);
		position: absolute;
		*{
		color: #fff;
		}
		@include border-radius(50%);
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		i{
			display: none;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);
		}
		
	}
    .arrows {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
    }
    #twitterPrev{
		left: 40px;
		background-image: url(../images/PreviousTwitter.png);
		@include transform( scale(1) translateX(-50%) translateY(-50%));
		&:hover{
			@include transform(translateX(-50%) translateY(-50%) scale(1.2));
		}
	}
	#twitterNext{
		right: 40px;
		background-image: url(../images/NextTwitter.png);
		@include transform( scale(1) translateX(50%) translateY(-50%));
		&:hover{
			@include transform(translateX(50%) translateY(-50%) scale(1.2));
		}
	}
    
    
    .twitterSection {
//        margin-top: 50px;
//        width: 100%;
//        max-width: 1400px;
//        margin-bottom: 48px;
        
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        margin-top: 100px;
        .titleWrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            h1 {
//                font-family: $font-stack;
                color: $mainColor2;
                font-weight: 300;
                font-size: 50px;
                position: relative;
                line-height: 90px;
                margin-top: 0;
                span {
//                    font-family: $font-heading;
                    font-weight: 600;
                    color: $mainColor2;
                }
                &:after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 200px;
                    background-color: $mainColor2;
                    bottom: 0;
                    left: calc(50% - 100px);
                }
            }
        }
        .sliderControls {
            max-width: 1140px;
            margin: 0 auto;
            position: relative;
            .widgetTwitterNext, .widgetTwitterPrev {
                position: absolute;
                display:inline-block;
                width: 20px;
                height: 20px;
                z-index:8;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                @include transition($t1);
                margin-top: 120px;
                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .widgetTwitterPrev {
                background-image: url(../images/TweetLeftArrow.svg);
                margin-top: 120px;
                left: 0px;
            }
            .widgetTwitterNext {
                background-image: url(../images/TweetRightArrow.svg);
                margin-top: 120px;
                right: 0;
            }
        }
        .twitterImage {
            height: 300px !important;
            background-size: cover;
            &.noImage {
                .tweetContainer {
                    .text {
                        background-color: $themeColor;
                        height: 100%;
                        .content {
                            overflow: visible;
                        }
                    }
                }
            }
            .tweetContainer {
                .text {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    font-weight: 300;
                    background-color: rgba($themeColor,0.8);
                    padding: 20px;
                    height: 110px;
                    .twitterNameContainer {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        font-size: 16px;
                        .titleContainer {
                            .title {
                                font-weight: bold;
                                margin: 0;
                            }
                            .twitterHandle {
                                margin: 0;
                                text-transform: uppercase;
                                font-weight: bold;
                                color: #fff;
                                svg {
                                    vertical-align: middle;
                                }
                            }
                        }
                        .twitterDate {
                            font-size: 12px;
                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                    .content {
                        max-height: 40px;
                        overflow: hidden;
                        line-height: 20px;
                        margin-right: 5px;
                        margin-top: 0;
                        font-weight: 400;
                        color: #fff;
                        font-size: 15px;
                        line-height: 19px;
                    }
                }
            }
            &:hover {
                .text {
                    height: 100%;
                    .content {
                        max-height: 200px;
                    }
                }
            }
        }
    }
    
    
    .instagram {
        display: flex;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        margin-top: 40px;
        .post {
            width: 25%;
            height: 300px;
            margin-right: 40px;
            background-size: cover;
            position: relative;
            max-width: 320px;
            &:last-child {
                margin-right: 0;
            }
            .instalogo {
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 25px;
            }
        }
    }
    
    .newsletterLink {
        max-width: 1400px;
        margin: 0 auto;
        text-align: center;
        background-color: $themeColor;
        margin-top: 50px;
        a {
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .title {
                text-transform: uppercase;
                font-weight: bold;
                color: #fff;
                font-size: 22px;
                line-height: 22px;
            }
            .downloadIcon {
                svg {
                    width: 22px;
                    margin-left: 10px;
                }
            }
        }
        &:hover {
            background-color: #7a0b00;
        }
    }
}
.overlay2{
    position: absolute;
    opacity: 0.2;
   	top: 55%;
   	@include transform(translateY(-50%));
    right: -10%;
    height: 110%;
    z-index: 3;
}
.backtotop{
	background-color: #740800;
	text-align: center;
	text-transform: uppercase;
	padding: 10px 20px;
	cursor: pointer;
	color: #fff
}


.SearchContainer{
	display: none;
	position: absolute;
	top: 22px;
    right: 0px;
    @include transform(translateY(100%))
    background-color: #e0e0e0;
    padding: 7px;
    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    input[type=submit]{
	    background-color: $themeColor;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    i{
	    	color: #fff !important;
	    }
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
    }
}
.BreadCrumbContainer{

	.divider,
	.here{
		color: $themeColor;
	}
	background-color: #f4f4f4;
	text-align: right;
	padding: 10px 40px;
	*{
		text-transform: uppercase;
		font-size: 1em;
	}
}

.innerContent{
	width: 100%;
	float: left;
	position: relative;
	padding: 5%;
	.pageTitle{
		border-bottom: 1px solid $themeColor;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
}
.PageImageOne, 
.PageImageTwo{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
}

.PageImageOne{
	margin-bottom: 25px;
}


.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #475f79 !important;
		@include transition($t1);
		&:hover{
			background-color: #475f79 !important;
			color: #fff !important;
		} 
	}
}
.flexs{
	max-width: 800px;
	min-width: 0px !important;
}

.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}



.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	background-color: #fff;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				background-color: #475f79;
				    font-size: 1em;
				.show-month, .next, .prev {
					display:inline-block;
					color: #FFFFFF;
					font-weight: bold;
					font-weight: lighter;
    				font-size: 1.3em;
					i {
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: #475f79;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			color: #a9afb9;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
			position: relative;
				padding: 10px 0px !important;
				z-index: 1;
				text-align: center !important;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				z-index: 1;
				&.hasEvent:hover {
				z-index: 9990;
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}
		.hasEvent {
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 4px 4px;
		}
		.today {
			color: #676b71;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			color: #efefef;
		}
	} 
	.calendar-day:hover, .selected {
		background-color: rgba(71, 95, 121, 0.05) !important;
		
					color: $themeColor;
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: #475f79 ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 10px 20px 10px 20px;
}

.calendar-widget-table thead tr th .show-month{
	font-size: 1.2em !important;
}

.calendar-widget-table thead tr th{
	    background-color: #c50029 !important;
}
#allEvents ul li, #HomepageEvents ul li{
	    background-color: #c50029 !important;
	    *{
	    color: #fff !important;
	    }
}
#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}


.contentPage table{
	border-collapse: collapse;
    border: 1px solid #c7c7c7;
    }
.contentPage table th,
.contentPage table td{
padding: 10px 5px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #c7c7c7;
}

.contentPage table th{
	background-color: #efececd1;
}

.PortalPageLink{
	padding-bottom: 100%;
}
.PortalLinkInner{
	width: 95%;
	height: 95%;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
	background-color: $themeColor;
	overflow: hidden;
	span{
		color: #fff;
		position: absolute;
		text-transform: uppercase;
		width: 100%;
		padding: 10px;
		font-size: 1.3em;
		text-align: center;
		left: 50%;
		@include transform($center);
		top: 50%;
		z-index: 2;
	}
	.darken{
		@include stretchCover(rgba(0,0,0, 0.5), absolute, 1)
	}
	a{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 3;
	}
}
.PortalLinkbg{
	position: absolute;
	@include transition($t1);
	background-position: center;
	opacity: 1;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-image: url(../images/bg.jpg);
	background-size: cover;
	background-position: center;
}
.PortalLinkInner:hover{
	.PortalLinkbg{
		opacity: 0.4;
	}
}

/* -------------------- Site Build -------------------- **/

.FadeIn{
	@include transform(scale(0.8));
	@include transition(1s);
	opacity: 0;
}
.FadeInActive{
	@include transform(scale(1));
	opacity: 1;
}

.emailFooter{
	&:hover{
		color: $themeColor;
	} 
}

table *{
	font-size: 1em;
}

.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
	color: #fff;
	}
}


.error {
    display: inline-block;
    border: 1px solid #da8486;
    margin: 10px 0px 20px 0px;
    padding: 5px 20px;
    font-size: 1em;
    color: #da8486;
    background-color: #ffdfdf;
}

