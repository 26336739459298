
/* ------------  General Site Responsive ----------- */
@media all and (max-width: 1500px)  {
    .bannerText {
        .tagline {
            font-size: 25px;
            line-height: 30px;
        }
    }
}

@media all and (max-width: 1400px)  {
    #Home2 {
        .instagram {
            .post {
                width: 33.3%;
                max-width: inherit;
                &:nth-child(4) {
                    display: none;
                }
                &:nth-child(3) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}
}
@media all and (max-width: 1030px)  {
	.parallax{
		@include transform(translateY(0px) !important);
	}
}
@media all and (max-width: 1200px)  {
	#MenuIcon{
		display: block;
	}
	#Menu{
		display: none;
	}

	.topIcons {
	    right: 58px !important;
    	top: 5px;
	}
}
@media all and (max-width: 670px)  {
	.PortalPageLinkOuter{
		width: 50% !important;
	}
}

@media all and (max-width: 1100px)  {
    #Home2 {
        .instagram {
            .post {
                width: 50%;
                &:nth-child(4), &:nth-child(3) {
                    display: none;
                }
                &:nth-child(2) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media all and (max-width: 1050px)  {
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
	.overlay2{
		height: auto !important;
	}
}

@media all and (max-width: 600px)  {
    #Home2 {
        .instagram {
            .post {
                width: 100%;
                &:nth-child(4), &:nth-child(3), &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media all and (max-width: 550px)  {
	.PortalPageLinkOuter{
		width: 100% !important;
	}
}
@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 880px)  {
	.bannerText {
		width: calc(100% - 100px);
    	font-size: 1.3em !important;
        .tagline {
            font-size: 18px;
            line-height: 22px;
        }
    }
}
@media all and (max-width: 600px)  {
    #Home2 {
        .newsletterLink {
            a {
                .title {
                    font-size: 18px;
                }
            }
        }
    }
	#Home3{
		.col-4{
			width: 100% !important;
			clear: both;
		}
		.QuickLink{
			height: 300px !important;
		}
	}
	.QuickLink-1{
		.QuickLinkBG{
			opacity: 0.4;
		}
		&:hover{
			background-color: #000;
			.QuickLinkBG{
				opacity: 0.6;
			}
		}
	}
}
@media all and (max-width: 500px)  {
	.bannerText {
    	font-size: 1em !important;
        .tagline {
            padding: 0;
        }
    }
}

@media all and (max-width: 800px)  {

	#NewsSection,
	#Home4{
		.pageTitle {
	    	text-transform: uppercase;
	    	font-size: 1.2em;
	    }
	}
	.QuickLink .QuickLinkTitle {
    font-size: 1em;}


	.footerTop{
		text-align: center;
		display: block !important;
		.col-7,
		.col-5{
			width: 100%;
			clear: both;
		}
		#gmap_canvas{
			position: relative;
			height: 500px;
		}
	}

    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
    .FooterBottom{
		text-align: center;
		.tar{

		text-align: center !important;
		}
    	.col-4,
    	.col-8{
    	padding: 20px !important;
    		width: 100%;
			clear: both;
    	}
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
	.innerContent .pageTitle{
		font-size: 1.3em;
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }

    #Home4{
	   .pageTitle {
		    max-width: 150px !important;
		    margin: 0 auto !important;
		}
    }

    .newsImg{
    	display: none !important;
    }
    .newsText{
   	 	padding-left: 0px !important;
    	text-align: center;
    }
    #NewsSection a {
    	width: 100%;
    	right: 0px !important;
    }
    .CalendarInner{
    text-align: center !important;
    	*{
    		width: 100%;
    		clear: both !important;
    	}
    	.calText{
		    padding-left: 0px !important;
    	}
    	.DateContent{
		    padding-left: 0px !important;
		    padding-top: 20px !important;
		}
    }
}

@media all and (max-width: 1197px)  {
	.sideBarContainer{
		.sidebar-heading a{
			font-size: 1.2em !important;
		}
	}
	#sidebar button {
	    top: 12px !important;
	    right: 10px !important;
	}
	.PageImageOne, 
	.PageImageTwo{
		display: none !important;
	}
}
@media all and (max-width: 1000px)  {

	.innerContent{
		padding: 0px !important;
	}
	.sideBarContainer,
	.MainContentArea{
		clear: both;
		width: 100%;
		padding: 20px !important;
	}
	.sideBarContainer{
		padding-bottom: 0px !important;
	}
	#sidebar {
		margin-bottom: 0px !important;
   	 	padding-top: 10px !important;
   	 	padding-bottom: 10px !important;
	}
}
@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
	.topIcons{
		display: none !important;
	}
	.homebtn span,
	.homebtn img {
	    height: 40px;
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */
